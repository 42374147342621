<template>
    <div class="observer"></div>
</template>

<script>
    export default {
        props: ['options'],
        data: () => ({
            observer: null,
        }),
        mounted() {
            // Небольшой таймаут для корректного срабатывания при первом появлении на странице
            setTimeout(()=> {
                const options = this.options || {};
                this.observer = new IntersectionObserver(([entry]) => {
                    if (entry && entry.isIntersecting) {
                        this.$emit("intersect");
                    }
                }, options);

                this.observer.observe(this.$el);
            }, 100);

        },
        destroyed() {
            this.observer.disconnect();
        },
    };
</script>
